import AppOptions from './config/AppOptions';
import Workspaces from './config/Workspaces';

/**
 * Request to fetch configuration data
 * @param {string} url - The URL for the request
 * @returns {Promise<object>} - An object containing configuration data
 */
async function fetchWorkspaceConfig(url) {
  try {
    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    });

    const data = await response.json();

    // Check if the id field is missing and detail exists
    if (!data.id && data.detail) {
      throw new Error(`Workspace config missing 'id' field. Response: ${JSON.stringify(data.detail)}`);
    }

    return data;
  } catch (error) {
    console.error('Error fetching workspace:', error);
    return null;
  }
}

/**
 * Init Workspace
 * Find config based on location
 *
 * @param {string|undefined|null} domain
 *
 * @returns {object}
 */
export async function initWorkspace(defaultAppOptions, domain) {
  let currentAppOptions = JSON.parse(localStorage.getItem('appOptions'));
  let isWorkspacesEnable = JSON.parse(localStorage.getItem('isWorkspacesEnable'));
  let hosts = currentAppOptions?.defaultHosts ?? [];

  // Handle if we have new appOptions
  if (currentAppOptions?.version !== defaultAppOptions.version) {
    localStorage.clear();
    currentAppOptions = null;
  }

  // Check is valid options
  if (!currentAppOptions || Object.keys(AppOptions).length - 3 > Object.keys(currentAppOptions).length) {
    let workspace;

    currentAppOptions = {
      ...defaultAppOptions,
      ...(workspace || {}),
    };

    hosts = currentAppOptions.defaultHosts ?? [];

    if (!hosts.includes(location.hostname)) {
      const data = await fetchWorkspaceConfig(`${currentAppOptions.defaultServer}/api/users/domains/settings/${location.hostname}`);

      if (data) {
        workspace = data;
        currentAppOptions = {
          ...defaultAppOptions,
          ...(workspace || {}),
        };
      }
    }

    currentAppOptions.domain = domain || currentAppOptions.domain;
    isWorkspacesEnable = !workspace || workspace.id === 'app-dev' || location.hostname.includes('localhost');

    localStorage.setItem('isWorkspacesEnable', JSON.stringify(isWorkspacesEnable));
    localStorage.setItem('appOptions', JSON.stringify(currentAppOptions));
  }

  window.appOptions = currentAppOptions;
  window.isWorkspacesEnable = isWorkspacesEnable;
  window.isUnlockedDomain = !!hosts.find((x) => location.hostname.includes(x)) || currentAppOptions.id === 'default' || currentAppOptions.id === 'app-dev';

  document.getElementById('favicon').href = currentAppOptions.logoFavicon ?? currentAppOptions.logoSrc;
  document.getElementById('app-title').textContent = currentAppOptions.title || 'OrderAdmin';

  return currentAppOptions;
}
